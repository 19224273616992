@charset "UTF-8";body,body[data-theme=default],body[data-theme=blue],body[data-theme=yellow],body[data-theme=beige],body[data-theme=green] {
  --primary-red-500: #e5353e;
  --primary-red-300: #ff6f75;
  --primary-red-50: #ffe6e7;
  --secondary-blue-500: #5790df;
  --secondary-blue-300: #84b4f5;
  --secondary-blue-50: #ebeffa;
  --surface-gray-900: #191919;
  --surface-gray-700: #4c4c4c;
  --surface-gray-500: #808080;
  --surface-gray-400: #adadad;
  --surface-gray-300: #ccc;
  --surface-gray-200: #e0e0e0;
  --surface-gray-100: #ebebeb;
  --surface-gray-50: #f5f5f5;
  --surface-bw-white: #fff;
  --background-gray-100: #ebebeb;
  --background-gray-50: #f5f5f5;
  --background-bw-white: #fff;
  --sheet-background-gray-50: #f5f5f5;
  --sheet-background-bw-white: #fff;
  --nav-bar-background-bw-white: #fff;
  --on-image-bw-white: #fff;
  --on-image-white-70: rgba(255, 255, 255, .7);
  --on-image-white-36: rgba(255, 255, 255, .36);
  --on-image-black-90: rgba(0, 0, 0, .9);
  --on-image-black-60: rgba(0, 0, 0, .6);
  --on-image-black-36: rgba(0, 0, 0, .36);
  --outline-black-8: rgba(0, 0, 0, .08);
  --overlay-black-90: rgba(0, 0, 0, .9);
  --overlay-black-60: rgba(0, 0, 0, .6);
  --overlay-black-36: rgba(0, 0, 0, .36);
  --segmentation-black-4: rgba(0, 0, 0, .04);
  --bw-black: #000;
  --bw-white: #fff;
  --sd2: rgba(0, 0, 0, .06);
  --sd3: rgba(0, 0, 0, .06);
  --sd4: rgba(0, 0, 0, .07);
  --sd5: rgba(0, 0, 0, .08);
  --sd6: rgba(0, 0, 0, .09);
  --sd7: rgba(0, 0, 0, .1);
  --sd8: rgba(0, 0, 0, .12);
  --sd12: rgba(0, 0, 0, .16);
  --sd16: rgba(0, 0, 0, .16);
  --on-image-surface-black-4: rgba(0, 0, 0, .04);
  --on-image-surface-black-8: rgba(0, 0, 0, .08)
}

body[data-theme=blue] {
  --surface-gray-900: rgba(0, 0, 0, .9);
  --surface-gray-700: rgba(0, 0, 0, .7);
  --surface-gray-500: rgba(0, 0, 0, .48);
  --surface-gray-400: rgba(0, 0, 0, .36);
  --surface-gray-300: rgba(0, 0, 0, .32);
  --surface-gray-200: rgba(0, 0, 0, .24);
  --surface-gray-100: rgba(0, 0, 0, .12);
  --surface-gray-50: rgba(0, 0, 0, .08);
  --surface-bw-white: rgba(0, 0, 0, .04);
  --background-gray-100: #cedde1;
  --background-gray-50: #dcebef;
  --background-bw-white: #edf5f7;
  --sheet-background-gray-50: #dcebef;
  --sheet-background-bw-white: #edf5f7;
  --nav-bar-background-bw-white: #edf5f7
}

body[data-theme=yellow] {
  --surface-gray-900: rgba(0, 0, 0, .9);
  --surface-gray-700: rgba(0, 0, 0, .7);
  --surface-gray-500: rgba(0, 0, 0, .48);
  --surface-gray-400: rgba(0, 0, 0, .36);
  --surface-gray-300: rgba(0, 0, 0, .32);
  --surface-gray-200: rgba(0, 0, 0, .24);
  --surface-gray-100: rgba(0, 0, 0, .12);
  --surface-gray-50: rgba(0, 0, 0, .08);
  --surface-bw-white: rgba(0, 0, 0, .04);
  --background-gray-100: #e3d0a1;
  --background-gray-50: #efe2c0;
  --background-bw-white: #f4ecd1;
  --sheet-background-gray-50: #efe2c0;
  --sheet-background-bw-white: #f4ecd1;
  --nav-bar-background-bw-white: #f4ecd1
}

body[data-theme=beige] {
  --surface-gray-900: rgba(0, 0, 0, .9);
  --surface-gray-700: rgba(0, 0, 0, .7);
  --surface-gray-500: rgba(0, 0, 0, .48);
  --surface-gray-400: rgba(0, 0, 0, .36);
  --surface-gray-300: rgba(0, 0, 0, .32);
  --surface-gray-200: rgba(0, 0, 0, .24);
  --surface-gray-100: rgba(0, 0, 0, .12);
  --surface-gray-50: rgba(0, 0, 0, .08);
  --surface-bw-white: rgba(0, 0, 0, .04);
  --background-gray-100: #ebe6da;
  --background-gray-50: #f5f1e8;
  --background-bw-white: #faf9f4;
  --sheet-background-gray-50: #f5f1e8;
  --sheet-background-bw-white: #faf9f4;
  --nav-bar-background-bw-white: #faf9f4
}

body[data-theme=green] {
  --surface-gray-900: rgba(0, 0, 0, .9);
  --surface-gray-700: rgba(0, 0, 0, .7);
  --surface-gray-500: rgba(0, 0, 0, .48);
  --surface-gray-400: rgba(0, 0, 0, .36);
  --surface-gray-300: rgba(0, 0, 0, .32);
  --surface-gray-200: rgba(0, 0, 0, .24);
  --surface-gray-100: rgba(0, 0, 0, .12);
  --surface-gray-50: rgba(0, 0, 0, .08);
  --surface-bw-white: rgba(0, 0, 0, .04);
  --background-gray-100: #c9e0cb;
  --background-gray-50: #e0eee1;
  --background-bw-white: #ebfaeb;
  --sheet-background-gray-50: #e0eee1;
  --sheet-background-bw-white: #ebfaeb;
  --nav-bar-background-bw-white: #ebfaeb
}

body[data-theme=dark] {
  --primary-red-500: #ff4d55;
  --primary-red-300: rgba(229, 53, 62, .48);
  --primary-red-50: rgba(229, 53, 62, .16);
  --secondary-blue-500: #6da2ed;
  --secondary-blue-300: rgba(109, 162, 237, .48);
  --secondary-blue-50: rgba(114, 148, 227, .16);
  --surface-gray-900: rgba(255, 255, 255, .6);
  --surface-gray-700: rgba(255, 255, 255, .5);
  --surface-gray-500: rgba(255, 255, 255, .4);
  --surface-gray-400: rgba(255, 255, 255, .36);
  --surface-gray-300: rgba(255, 255, 255, .32);
  --surface-gray-200: rgba(255, 255, 255, .24);
  --surface-gray-100: rgba(255, 255, 255, .12);
  --surface-gray-50: rgba(255, 255, 255, .08);
  --surface-bw-white: rgba(255, 255, 255, .08);
  --background-gray-100: #0a0a0a;
  --background-gray-50: #111;
  --background-bw-white: #191919;
  --sheet-background-gray-50: #191919;
  --sheet-background-bw-white: #1f1f1f;
  --nav-bar-background-bw-white: #1f1f1f;
  --on-image-bw-white: rgba(255, 255, 255, .9);
  --on-image-white-70: rgba(255, 255, 255, .6);
  --on-image-white-36: rgba(255, 255, 255, .36);
  --on-image-black-90: rgba(0, 0, 0, .9);
  --on-image-black-60: rgba(0, 0, 0, .6);
  --on-image-black-36: rgba(0, 0, 0, .36);
  --outline-black-8: rgba(255, 255, 255, .12);
  --overlay-black-90: rgba(0, 0, 0, .9);
  --overlay-black-60: rgba(0, 0, 0, .6);
  --overlay-black-36: rgba(0, 0, 0, .36);
  --segmentation-black-4: rgba(0, 0, 0, .7);
  --bw-black: #000;
  --bw-white: #fff;
  --sd2: rgba(0, 0, 0, 0);
  --sd3: rgba(0, 0, 0, 0);
  --sd4: rgba(0, 0, 0, 0);
  --sd5: rgba(0, 0, 0, 0);
  --sd6: rgba(0, 0, 0, 0);
  --sd7: rgba(0, 0, 0, 0);
  --sd8: rgba(0, 0, 0, 0);
  --sd12: rgba(0, 0, 0, 0);
  --sd16: rgba(0, 0, 0, 0);
  --on-image-surface-black-4: rgba(255, 255, 255, .08);
  --on-image-surface-black-8: rgba(255, 255, 255, .12)
}

@charset "UTF-8";p,h1,h2,h3,h4,h5,h6,ul,dl,ol,dd,form,figure,blockquote {
                   margin: 0
                 }

em {
  font-style: normal
}

h1,h2,h3,h4,h5,h6,input,textarea,select {
  font-size: 100%
}

input,textarea,select {
  font-family: inherit;
  box-sizing: border-box
}

html {
  font-size: 100PX;
  font-size: calc(100PX + 2 * (100vw - 360PX) / 60)
}

body {
  fill: currentColor;
  font-family: sans-serif;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-user-select: none;
  -webkit-text-size-adjust: none;
  -webkit-touch-callout: none;
  -webkit-font-smoothing: inherit;
  margin: 0;
  font-size: .14rem;
  line-height: .22rem;
  box-sizing: border-box
}

[data-theme=default] body {
  color: #191919
}

[data-theme=blue] body,[data-theme=yellow] body,[data-theme=beige] body,[data-theme=green] body {
  color: rgba(0,0,0,.9)
}

[data-theme=dark] body {
  color: rgba(255,255,255,.6)
}

::-webkit-search-cancel-button {
  -webkit-appearance: none
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-search-results-button {
  -webkit-appearance: none
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

input,textarea {
  -webkit-user-select: auto;
  outline: none
}

:root,html[data-theme=default],html[data-theme=blue],html[data-theme=yellow],html[data-theme=beige],html[data-theme=green] {
  --Primary: #e5353e;
  --Primary_1: #ff6f75;
  --Primary_2: #ffe6e7;
  --Secondary: #5790df;
  --Secondary_1: #84b4f5;
  --Secondary_2: #ebeffa;
  --Surface: #191919;
  --Surface_1: #4c4c4c;
  --Surface_2: #808080;
  --Surface_3: #adadad;
  --Surface_4: #ccc;
  --Surface_5: #e0e0e0;
  --Surface_6: #ebebeb;
  --Surface_7: #f5f5f5;
  --Surface_8: #fff;
  --Background: #f5f5f5;
  --Background_1: ;
  --SheetBackground: #f5f5f5;
  --SheetBackground_1: #fff;
  --Outline: rgba(0, 0, 0, .08);
  --Segmentation: rgba(0, 0, 0, .04);
  --Overlay: rgba(0, 0, 0, .9);
  --Overlay_1: rgba(0, 0, 0, .6);
  --Overlay_2: rgba(0, 0, 0, .36)
}

html[data-theme=dark] {
  --Primary: #ff4d55;
  --Primary_1: rgba(229, 53, 62, .48);
  --Primary_2: rgba(229, 53, 62, .16);
  --Secondary: #6da2ed;
  --Secondary_1: rgba(109, 162, 237, .48);
  --Secondary_2: rgba(114, 148, 227, .16);
  --Surface: rgba(255, 255, 255, .6);
  --Surface_1: rgba(255, 255, 255, .5);
  --Surface_2: rgba(255, 255, 255, .4);
  --Surface_3: rgba(255, 255, 255, .36);
  --Surface_4: rgba(255, 255, 255, .32);
  --Surface_5: rgba(255, 255, 255, .24);
  --Surface_6: rgba(255, 255, 255, .12);
  --Surface_7: rgba(255, 255, 255, .08);
  --Surface_8: rgba(255, 255, 255, .08);
  --Background: #111;
  --Background_1: #191919;
  --SheetBackground: #191919;
  --SheetBackground_1: #1f1f1f;
  --Outline: rgba(255, 255, 255, .12);
  --Segmentation: rgba(0, 0, 0, .7);
  --Overlay: rgba(0, 0, 0, .9);
  --Overlay_1: rgba(0, 0, 0, .6);
  --Overlay_2: rgba(0, 0, 0, .36)
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

[data-theme=default] h3 {
  color: #191919;
}

ul, ol {
  list-style: none;
  padding: 0;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}


@font-face {
  font-family: "HYHaiDeFeng";
  /*src: url("../public/statics/font/HYHaiDeFeng.ttf") format('truetype');*/
  font-weight: normal;
  font-style: normal;
}