.App {

}

[data-theme=default] .content_wrap_home,
[data-theme=default] .content_wrap {
    background-color: #f5f5f5;
}

.content_wrap_home {
    padding-bottom: 0.5rem;
    padding-top: 0.08rem;
}

.content_wrap {
    padding-bottom: 0.5rem;
}

.container {
    min-height: calc(100vh - 1.4rem);
}
